
/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

export const backofficeRoutes = [
  {
    path: '/admin',
    component: () => import('@/modules/backoffice/views/AdminSection'),
    meta: {
      requiredRoles: ['role_admin']
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('../backoffice/views/Dashboard'),
        props: true
      },
      {
        path: 'establishments',
        name: 'establishments-list',
        component: () => import('../establishments/views/EstablishmentsList')
      },
      {
        path: 'establishment/:id',
        name: 'establishments-view',
        component: () => import('../establishments/views/EstablishmentView'),
        props: true
      },
      {
        path: 'users',
        name: 'users-list',
        component: () => import('../users/views/UsersList')
      },
      {
        path: 'services',
        name: 'services-list',
        component: () => import('../services/components/list/ServicesList'),
        props: true
      },
      {
        path: 'professionnals',
        name: 'professionals-list',
        component: () => import('../professionals/components/backoffice/ProfessionalsList'),
        props: true
      },
      {
        path: 'specialities',
        name: 'specialities-list',
        component: () => import('../specialities/views/SpecialitiesList'),
        props: true
      },
      {
        path: 'forms',
        name: 'forms-list',
        component: () => import('../forms/views/backoffice/FormsList'),
        props: true
      },
      {
        path: 'forms/:id',
        name: 'form-write',
        component: () => import('../forms/components/FormUpdate'),
        props: true
      },
      {
        path: 'surveys',
        name: 'surveys-list',
        component: () => import('../surveys/views/backoffice/SurveysList'),
        props: true
      },
      {
        path: 'surveys/:id',
        name: 'survey-write',
        component: () => import('../surveys/components/SurveyUpdate'),
        props: true
      },
      {
        path: 'import',
        name: 'import',
        component: () => import('../backoffice/views/Import')
      }
    ]

  }
]
