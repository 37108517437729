/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import axios from 'axios'
import config from '@/config'

export function login(email, password) {
  // endpoint is not prefixed with "/api"
  return axios.post(config.urls.api + '/authentication_token', {
    email,
    password
  }, {
    withCredentials: true
  })
}

export function logout() {
  return axios.get(config.urls.api + '/logout', {
    withCredentials: true
  })
}

export function getToken() {
  return axios.get(config.urls.api + '/authentication_token', {
    withCredentials: true
  })
}

export function refresh() {
  return axios.post(config.urls.api + '/refresh_token', null, {
    withCredentials: true
  })
}

export function validate(username, temporaryCode) {
  temporaryCode = parseInt(temporaryCode)
  return axios.post(config.urls.api + '/account/validate', {
    username,
    temporaryCode
  })
}

export function initPasswordWithCode(username, temporaryCode, password) {
  temporaryCode = parseInt(temporaryCode)
  return axios.post(config.urls.api + '/account/init_password', {
    username,
    temporaryCode,
    password
  })
}

export function resetPasswordWithToken(token, password) {
  return axios.post(config.urls.api + '/account/reset_password', {
      password
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export function temporaryTokenRequest(username) {
  return axios.post(config.urls.api + '/temporary_token', {
      username
    }
  )
}

export function acceptCGU(userUuid) {
  return axios.patch(config.urls.api + `/api/users/${userUuid}/accept_cgu`, null, {
    withCredentials: true
  })
}

export function changeEmailConfirmation(userId, token) {
  return axios.post(config.urls.api + '/change_email/confirmation', {
    user: userId,
    token
  })
}
