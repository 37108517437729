<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-snackbar v-model="snackbar" fixed top right max-height="500" :color="typeColor" class="elevation-x" min-width="250" timeout="3000">
    <div class="d-flex align-center">
      <font-awesome-icon class="mr-4" size="lg" :class="typeColor" :icon="['fad', typeIcon]" />
      <v-card-title class="font-alt font-weight-light pa-0 white--text text-subtitle-1">{{ title }}</v-card-title>
    </div>
  </v-snackbar>
</template>

<script>
  import {mapMutations} from 'vuex'
  export default {
    name: 'Snackbar',
    data() {
      return {
        snackbar: null,
        title: null,
        type: null
      }
    },
    computed: {
      dialogType: function () {
        return {
          successdialog: this.type === 'success',
          errordialog: this.type === 'error',
          infodialog: this.type === 'info'
        }
      },
      typeColor() {
        let color
        switch (this.type) {
          case 'success':
            color = 'success'
            break
          case 'error':
            color = 'red lighten-2'
            break
          case 'info':
            color = 'primary'
            break
        }
        return color
      },
      typeIcon() {
        let icon
        switch (this.type) {
          case 'success':
            icon = 'check-double'
            break
          case 'error':
            icon = 'exclamation-circle'
            break
          case 'info':
            icon = 'info-circle'
            break
          default:
            icon = 'info-circle'
            break
        }
        return icon
      }
    },
    created() {
      this.$root.$on('snackbar:open', async ({snackbar, type, title}) => {
        this.snackbar = snackbar
        this.type = type
        this.title = title
        this.toggleConfirmSnackbar(true)
      })
    },
    methods: {
      ...mapMutations('layout', ['toggleConfirmSnackbar'])
    }
  }
</script>
