/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import {trim} from './string'

export function queryString(params) {
  return Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&')
}

export function currentHost() {
  return window.location.protocol +'//'+ window.location.host
}

export function currentURL() {
  return trim(currentHost() + window.location.pathname, '/')
}

export function getUrlSubdomain() {
  const blacklist = [
    'dev',
    'qa',
    'stg',
    'prd',
    'tst',
    'www',
    'mapread'
  ]
  let subdomain = window.location.host.split('.')[0]
  return blacklist.includes(subdomain) ? '' : subdomain
}

