/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'fr',
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  messages
})

export default i18n
