/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

export default {
  namespaced: true,
  state:  {
    snackbarMessage: false,
    customDomain: ''
  },
  mutations: {
    toggleConfirmSnackbar(state, value) {
      state.snackbarMessage = value
    },
    setCustomDomain(state, value) {
      const path = './img/assets/'+value+'/login-default-bg.jpg'
      let xhr = new XMLHttpRequest()
      xhr.open('HEAD', path, false)
      xhr.send()

      if (xhr.status == '404') {
        return false
      } else {
        state.customDomain = value
        return true
      }
    }
  }
}
