<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-snackbar v-model="$auth.sessionExpired" centered timeout="-1">
    Votre session a expiré. Veuillez vous reconnecter

    <template v-slot:action="{ attrs }">
      <v-btn color="blue" text v-bind="attrs" @click="reconnect">
        Se reconnecter
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  export default {
    name: 'SessionExpiredSnackbar',
    methods: {
      reconnect() {
        this.$auth.logout()
      }
    }
  }
</script>
