/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import Vue from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import utcPlugin from 'dayjs/plugin/utc'
import relativeTimePlugin from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isToday from 'dayjs/plugin/isToday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import customParseFormat  from 'dayjs/plugin/customParseFormat'

dayjs.extend(utcPlugin)
dayjs.extend(relativeTimePlugin)
dayjs.extend(localizedFormat)
dayjs.extend(isToday)
dayjs.extend(weekOfYear)
dayjs.extend(customParseFormat)
dayjs.locale('fr') // use French locale globally
window.dayjs = dayjs

Object.defineProperties(Vue.prototype, {
  $dayjs: {
    get() {
      return dayjs
    }
  }
})
