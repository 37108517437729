/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

export default {
  login: {
    chooseLanguage: 'Choisissez votre langue',
    email: 'E-mail',
    password: 'Mot de passe',
    useCode: 'J\'ai recu un code',
    signIn: 'Se connecter',
    lostPassword: 'Mot de passe oublié ?',
    errorMessage: 'Les identifiants ne sont pas valides. Veuillez vérifier votre e-mail et votre mot de passe.'
  },
  backoffice:{
    title: 'Backoffice',
    subTitle: 'Manager mon service de pread',
    myAccount: 'Mon compte',
    logout: 'Déconnexion'
  },
  $vuetify: {
    noDataText: 'Aucune donnée disponible',
    dataIterator: {
      rowsPerPageText: 'Éléments par page:',
      pageText: '{0}-{1} de {2}'
    }
  }
}
