/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

const DISABLE_CONSOLE = process.env.VUE_APP_DISABLE_CONSOLE === 'true'
import logger, {addHandler} from '@/services/logger'

// Register a console logs handler if enabled
if (!DISABLE_CONSOLE) {
  addHandler(require('@/services/logger-console-handler').default)
}

import Vue from 'vue'
import {Plugin as fragmentPlugin} from 'vue-fragment'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import authPlugin from './plugins/auth'
import i18n from '@/plugins/i18n'
import './plugins/dayjs'
import './styles/app.scss'
import {rules} from './services/rules'

Vue.config.productionTip = false

Vue.use(fragmentPlugin)
Vue.use(authPlugin, {router})

Vue.filter('pluralize', (word, amount) => (amount > 1 || amount === 0) ? `${word}s` : word)

Vue.prototype.$rules = rules

const app = new Vue({
  el: '#app',
  name: 'Root',
  components: {App},
  router,
  store,
  vuetify,
  i18n,
  iconfont: 'faSvg',
  created() {
    this.$on('error', error => logger.error(error))
  },
  render: h => h(App)
})

store.$app = app

export default app
