/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

const MINIMUM_LEVEL = process.env.VUE_APP_DEBUG_LEVEL
const LEVELS = ['verbose', 'info', 'warning', 'error']
const MINIMUM_LEVEL_INDEX = LEVELS.indexOf(MINIMUM_LEVEL)

const handlers = []

// Call every registered handlers if log level is sufficient
function createLog(level, messages) {
  let levelIndex = LEVELS.indexOf(level)

  if (levelIndex < MINIMUM_LEVEL_INDEX) {
    return
  }

  for (let handler of handlers) {
    handler(level, ...messages)
  }
}

// Register a new log handler : A callback that will be called with theses parameters :
// level, ...messages
export function addHandler(handler) {
  handlers.push(handler)
}

// noinspection JSUnusedGlobalSymbols
export default {
  log: (...messages) => {
    createLog('verbose', messages)
  },
  info: (...messages) => {
    createLog('info', messages)
  },
  warn: (...messages) => {
    createLog('warning', messages)
  },
  error: (...messages) => {
    createLog('error', messages)
  }
}
