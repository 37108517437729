/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import api from '@/services/api'

export default function (prefix) {
  return {
    get(id, config) {
      return api.get(`${prefix}/${id}`, config)
    },
    list(config) {
      return api.get(prefix, config)
    },
    create(data, config) {
      return api.post(prefix, data, config)
    },
    update(id, data, partial = false, config = {}) {
      if(partial) {
        return api.patch(`${prefix}/${id}`, data, {
          headers: {
            'content-type': 'application/merge-patch+json'
          },
          ...config
        })
      }
      return api.put(`${prefix}/${id}`, data, config)
    },
    delete(id, config) {
      return api.delete(`${prefix}/${id}`, config)
    }
  }
}
