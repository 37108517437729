/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import lists from '@/modules/professionals/store/lists'

export default {
  namespaced: true,
  modules: {
    lists
  }
}
