/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import * as establishments from '@/modules/establishments/api/establishments'
import api from '@/services/api'
import * as ApiServices from '@/modules/services/api/services'
import { column } from '@/utils/array'

export default {
  namespaced: true,
  state: {
    allEstablishments: [],
    activeEstablishmentId: null,
    activeEstablishmentServices: []
  },
  getters: {
    activeEstablishment(state) {
      return state.allEstablishments.find(establishment => establishment.id === state.activeEstablishmentId)
    }
  },
  mutations: {
    setAllEstablishments(state, establishments) {
      state.allEstablishments = establishments
    },
    setActiveEstablishment(state, {establishmentId, services}) {
      state.activeEstablishmentId = establishmentId
      state.activeEstablishmentServices = services
      sessionStorage.setItem('store.establishments.activeEstablishmentId', establishmentId)
    },
    addEstablishment(state, establishment) {
      state.allEstablishments.push(establishment)
    },
    upsertEstablishment(state, data) {
      let establishment = state.allEstablishments.find(establishment => establishment.id === data.id)
      if (!establishment) {
        state.allEstablishments.push(data)
      } else {
        Object.assign(establishment, data)
      }
    },
    setActiveForm(state, formId) {
      let activeEstablishment = state.allEstablishments.find(establishment => state.activeEstablishmentId === establishment.id)
      if (!activeEstablishment) {
        throw new Error('Cannot set active form without an active establishment')
      }
      activeEstablishment.activeForm = formId
    },
    setSurveyFeatureActivated(state, value) {
      let activeEstablishment = state.allEstablishments.find(establishment => state.activeEstablishmentId === establishment.id)
      if (!activeEstablishment) {
        throw new Error('Cannot call setSurveyFeatureActivated without an active establishment')
      }
      activeEstablishment.surveyFeatureActivated = value
    },
    setServicesActiveSurvey(state, survey) {
      let surveyServicesId = column(survey.services, 'id')
      state.activeEstablishmentServices.forEach(service => {
        if (surveyServicesId.includes(service.id)) {
          service.activeSurvey = survey['@id']
        }
      })
    }
  },
  actions: {
    createdEstablishment({commit}, item) {
      commit('addEstablishment', item)
    },
    async setActiveEstablishmentId({commit}, establishmentId) {
      let services = (await ApiServices.list({params: {establishment: establishmentId}})).data['hydra:member']
      commit('setActiveEstablishment', {establishmentId, services})
    },
    async getEstablishments({state, commit, dispatch}) {
      return await establishments.list()
        .then(response => {
          commit('setAllEstablishments', response.data['hydra:member'])
          let activeId = sessionStorage.getItem('store.establishments.activeEstablishmentId')
          if (activeId && state.allEstablishments.find(establishment => establishment.id === activeId)) {
            dispatch('setActiveEstablishmentId', activeId)
          }
          return [null]
        })
        .catch(error => {
          return [error.response]
        })
    },
    async activateForm({getters, commit}, formId) {
      let activeEstablishment = getters.activeEstablishment
      if (!activeEstablishment) {
        throw new Error('Cannot set active form without an active establishment')
      }

      await api.put(activeEstablishment['@id'], {
        activeForm: formId
      })
      commit('setActiveForm', formId)
    },
    async activateSurveyFeature({getters, commit}) {
      let activeEstablishment = getters.activeEstablishment
      if (!activeEstablishment) {
        throw new Error('Cannot activate survey feature without an active establishment')
      }
      await api.put(activeEstablishment['@id'], {
        surveyFeatureActivated: true
      })
      commit('setSurveyFeatureActivated', true)
    }
  }
}
