/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

export function trim(string, char = ' ') {
  if (string[string.length - 1] === char) {
    return trim(string.substring(0, string.length - 1), char)
  }
  return string
}

export function rtrim(string, char = ' ') {
  if (string[0] === char) {
    return trim(string.substring(1, string.length), char)
  }
  return string
}

export const ucfirst = str => {return str.charAt(0).toUpperCase() + str.slice(1)}

export function slugify(str, separator = '-') {
  return str
    .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/\s+/g, separator)
}
