/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import api from '@/services/api'

export default {
  getStays(params) {
    return api.get('/api/stays', {
      status: 'validated',
      params
    })
  },

  getStaysProfil(id) {
    return api.get('/api/stays/' + id)
  },

  getPatient(iriPatient) {
    return api.get(iriPatient)
  },

  getFormResponse(iriFormResponse) {
    return api.get(iriFormResponse)
  },

  getReminders() {
    return api.get('/api/reminders/')
  },

  postReminder() {
    return api.post('/api/reminders')
  },

  validateStay(id) {
    return api.patch('/api/stays/' + id,
      {status: 'validated'},
      {headers: {'Content-Type': 'application/merge-patch+json'}}
    )
  },

  cancelStay(id) {
    return api.patch('/api/admissions/' + id,
      {status: 'cancelled'},
      {headers: {'Content-Type': 'application/merge-patch+json'}}
    )
  },

  reopenStay(id) {
    return api.patch('/api/admissions/' + id,
      {status: 'in_progress'},
      {headers: {'Content-Type': 'application/merge-patch+json'}}
    )
  }
}
