/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

export default {
  login: {
    chooseLanguage: 'Choose your language',
    email: 'Email',
    password: 'Password',
    useCode: 'I have a code',
    signIn: 'Sign in',
    lostPassword: 'Forgot your password?',
    errorMessage: 'Invalid credentials. Please check your email and password.'
  },
  backoffice:{
    title: 'Backoffice',
    subTitle: 'Manage my pread service',
    myAccount: 'My account',
    logout: 'Logout'
  },
  $vuetify: {
    noDataText: 'No data available',
    dataIterator: {
      rowsPerPageText: 'Items per page:',
      pageText: '{0}-{1} of {2}'
    }
  }
}
