/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

/* eslint-disable no-console */
export default function (level, ...messages) {
  switch (level) {
    case 'verbose':
      console.log(...messages)
      break
    case 'info':
      console.info(...messages)
      break
    case 'warning':
      console.warn(...messages)
      break
    case 'error':
      console.error(...messages)
      break
    default:
      console.error(`[${level}]`, ...messages)
  }
}
