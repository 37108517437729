/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import Vue from 'vue'
import Vuex, {Store} from 'vuex'

import patients from '@/modules/patients/store'
import professionals from '@/modules/professionals/store'
import establishments from '@/modules/establishments/store'
import dashboard from '@/modules/backoffice/store'
import layout from '@/store/layout'

Vue.use(Vuex)

export default new Store({
  strict: process.env.NODE_ENV !== 'production', // https://vuex.vuejs.org/guide/strict.html
  modules: {
    patients,
    professionals,
    dashboard,
    layout,
    establishments
  }
})
