/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'
import config from '@/config'
import qs from 'qs'
import { getInstance as auth } from '@/plugins/auth'
import logger from '@/services/logger'

// https://github.com/RasCarlito/axios-cache-adapter
// To enable cache, api.get(url, { cache: { ignoreCache: false } })
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  ignoreCache: true
})

// https://github.com/axios/axios
const instance = new axios.create({
  baseURL: config.urls.api,
  adapter: cache.adapter,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/ld+json'
  },
  paramsSerializer: params => {
    return qs.stringify(params)
  }
})

instance.interceptors.request.use(config => {
  if (!config.url.startsWith('/api')) {
    config.url = '/api' + config.url
  }
  return config
}, error => {
  return Promise.reject(error)
})

// Add a response interceptor
instance.interceptors.response.use(response =>

    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response,
  (error) => {
    if (error.response && error.response.status === 401) {
      logger.warn('API returned 401 Unauthorized, consider session as expired', error)

      auth().sessionExpired = true
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

export default instance
