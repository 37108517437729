/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import Vue from 'vue'
import { login, refresh, getToken, logout } from '@/modules/auth/api'
import logger from '@/services/logger'
import api from '@/services/api'

const TIME_BEFORE_REFRESH = 60 // seconds

let instance

export function getInstance() {
  return instance
}

function createInstance(router) {
  return new Vue({
    name: 'AuthPlugin',
    data() {
      return {
        loading: true,
        username: null,
        user: null,
        roles: null,
        exp: null,
        iat: null,
        sessionExpired: false,
        sessionRefreshTimeout: null
      }
    },
    computed: {
      authenticated() {
        return this.username !== null
      },
      // Return true if at least one role match current user roles
      granted() {
        return roles => {
          if (!Array.isArray(roles)) {
            roles = [roles]
          }

          for (let role of roles) {
            if ('string' !== typeof role) {
              throw new Error('Expected role to be a string. Got ' + typeof role)
            }

            if (Array.isArray(this.roles) && this.roles.includes(role.toLowerCase())) {
              return true
            }
          }

          return false
        }
      }
    },
    watch: {
      exp(value) {
        if (!this.authenticated) {
          return
        }

        let expiresIn = Math.ceil(value - Date.now() / 1000)

        if (this.sessionRefreshTimeout) {
          clearTimeout(this.sessionRefreshTimeout)
        }

        this.sessionRefreshTimeout = setTimeout(this.refreshSession, (expiresIn - TIME_BEFORE_REFRESH) * 1000)
      }
    },
    async created() {
      await this.checkSession()
      this.loading = false
    },
    methods: {
      resetSession() {
        this.username = null
        this.user = null
        this.roles = null
        this.exp = null
        this.iat = null
        this.sessionExpired = false
      },
      async saveSession(data) {
        try {
          this.username = data.username
          this.user = (await api.get(data.user)).data
          this.roles = data.roles.map(role => role.toLowerCase())
          this.exp = data.exp
          this.iat = data.iat
          this.sessionExpired = false
        } catch (e) {
          logger.warn('Could not fetch user', e)
          this.$auth.sessionExpired = true
        }
      },
      async refreshSession() {
        try {
          let {data} = await refresh()
          if (data.exp - Date.now() / 1000 < TIME_BEFORE_REFRESH) {
            throw new Error('Session expiration must be greater than ' + TIME_BEFORE_REFRESH)
          }

          this.saveSession(data)
        } catch (e) {
          logger.warn('Token could not be refreshed', e)
          this.sessionExpired = true
        }
      },
      async login(email, password) {
        await this.saveSession((await login(email, password)).data)
      },
      async checkSession() {
        try {
          let {data} = await getToken()
          if (data !== null && typeof data === 'object') {
            await this.saveSession(data)
          }
        } catch (e) {
          if (e.response && e.response.status === 401) {
            return
          }
          logger.warn('Session check failed', e)
        }
      },
      async logout() {
        try {
          await logout()
        } catch (e) {
          //ignore it
        }
        this.resetSession()

        router.go(0)
      }
    }
  })
}

export default {
  install(Vue, {router}) {
    if (instance) return instance
    instance = createInstance(router)
    Vue.prototype.$auth = instance
  }
}
