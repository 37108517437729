/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import {list} from '@/modules/backoffice/api'

const state = {
  error: null,
  stats: {},
  totalItems: null,
  loading: false
}

const getters = {
  dashboardStats: state => state.stats,
  dashboardLoading: state => state.loading,
  dashboardError: state => state.error
}

const actions = {
  async fetchStats({commit}, establishment){
    commit('DASHBOARD_UPDATE_ERROR', null)
    commit('DASHBOARD_SET_ESTABLISHMENT', establishment || 'all')

    const params = establishment ? {params: {establishment: establishment}}: {params: {}}
    commit('DASHBOARD_UPDATE_LOADING', true)

    try {
      await list(params).then(response =>
        commit('DASHBOARD_SET_STATS', {establishment: params.params.establishment || 'all', data: response.data})
      )
    } catch (e) {
      commit('DASHBOARD_UPDATE_ERROR', e)
    } finally {
      commit('DASHBOARD_UPDATE_LOADING', false)
    }
  },
  resetStats({commit}, establishment){
    commit('DASHBOARD_RESET_ESTABLISHMENT', establishment || 'all')
  }
}

const mutations = {
  DASHBOARD_RESET_ESTABLISHMENT: (state, id) => (
    delete state.stats[id]
  ),
  DASHBOARD_SET_ESTABLISHMENT: (state, id) => (
    state.stats[id] = {timestamp: new Date().getTime()}
  ),
  DASHBOARD_SET_STATS: (state, {establishment, data}) => (
    state.stats[`${establishment}`] = {...state.stats[`${establishment}`], ...data}
  ),
  DASHBOARD_UPDATE_LOADING: (state, isLoading) => {
    state.loading = isLoading
  },
  DASHBOARD_UPDATE_ERROR: (state, error) => {
    state.error = error
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
