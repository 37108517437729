/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

export function empty(mixedVar, emptyValues = []) {
  let undef
  let key
  let i
  let len
  emptyValues = [undef, null, '', ...emptyValues]

  for (i = 0, len = emptyValues.length; i < len; i++) {
    if (mixedVar === emptyValues[i]) {
      return true
    }
  }

  if(Array.isArray(mixedVar)) {
    return mixedVar.length === 0
  }
  else if (typeof mixedVar === 'object') {
    for (key in mixedVar) {
      if (Object.prototype.hasOwnProperty.call(mixedVar, key) && !empty(mixedVar[key])) {
        return false
      }
    }
    return true
  }

  return false
}
