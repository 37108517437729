/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import en from 'vuetify/es5/locale/en'
import fr from 'vuetify/es5/locale/fr'
import { APTELECARE_ICONSET } from './fontawesome'
import i18n from './i18n'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: localStorage.getItem('theme:dark') === 'true',
    themes: {
      light: {
        primary: '#3d578e',
        secondary: '#37afaa',
        success: '#69b099'
      },
      dark: {
        primary: '#7595c5',
        secondary: '#37afaa',
        success: '#69b099'
      }
    }
  },
  lang: {
    locales: { en, fr },
    current: i18n.locale,
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    iconfont: 'faSvg',
    values: APTELECARE_ICONSET
  }
})
