/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import crud from '@/services/crud'
import api from '@/services/api'

let {get, list, create, update} = crud('/establishments')

export {get, list, create, update}

export function getStats(id) {
  return api.get(`/establishments/${id}/survey_stats`)
}
