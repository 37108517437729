<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-app v-if="!$auth.loading ">
    <header-bar v-if="!$route.meta.login && $auth.user" />
    <v-main>
      <router-view />
    </v-main>
    <session-expired-snackbar />
    <snackbar />
  </v-app>
</template>

<script>
  import HeaderBar from '@/modules/core/HeaderBar'
  import SessionExpiredSnackbar from '@/modules/auth/SessionExpiredSnackbar'
  import {mapState} from 'vuex'
  import Snackbar from '@/modules/core/components/Snackbar'
  import {getUrlSubdomain} from '@/utils/uri'

  export default {
    name: 'App',
    components: {SessionExpiredSnackbar, HeaderBar,Snackbar},
    computed: {
      ...mapState('layout', ['snackbarMessage'])
    },
    created() {
      this.$store.commit('layout/setCustomDomain', getUrlSubdomain())
    }
  }
</script>
