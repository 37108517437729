/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

export const professionalRoutes = [
  {
    path: '/dashboard',
    name: 'dashboard-professionnal',
    component: () => import('./views/Dashboard'),
    meta: {
      requiredRoles: ['role_professional']
    }
  },
  {
    path: '/admissions',
    component: () => import('./components/AdmissionSection'),
    meta: {
      requiredRoles: ['role_professional']
    },
    children: [
      {
        path: 'admissions-pending',
        name: 'admissions-pending',
        component: () => import('./components/list/AdmissionsPending'),
        props: true
      },
      {
        path: 'admissions-archived',
        name: 'admissions-archived',
        component: () => import('./components/list/AdmissionsArchived'),
        props: true
      },
      {
        path: 'admissions-cancelled',
        name: 'admissions-cancelled',
        component: () => import('./components/list/AdmissionsCancelled'),
        props: true
      },
      {
        path: 'admission-form',
        name: 'new-admission-form',
        component: () => import('./components/list/AdmissionForm'),
        props: true
      },
      {
        path: '',
        redirect: {name: 'admissions-pending'}
      }
    ]
  },
  {
    path: '/admissions/admission-card/:id',
    name: 'admission-card',
    component: () => import('./components/AdmissionCard'),
    props: true,
    meta: {
      requiredRoles: ['role_professional']
    }
  },
  {
    path: '/surveys',
    component: () => import('../surveys/views/professionals/SurveySection'),
    meta: {
      requiredRoles: ['role_professional']
    },
    children: [
      {
        path: 'surveys-pending',
        name: 'surveys-pending',
        component: () => import('./components/survey/SurveysPending'),
        props: true
      },
      {
        path: 'surveys-acquitted',
        name: 'surveys-acquitted',
        component: () => import('./components/survey/SurveysAcquitted'),
        props: true
      },
      {
        path: '',
        redirect: {name: 'surveys-pending'}
      }
    ]
  },
  {
    path: '/survey/survey-card/:id',
    name: 'survey-card',
    component: () => import('../surveys/views/professionals/SurveyCard'),
    props: true,
    meta: {
      requiredRoles: ['role_professional']
    }
  }
]
