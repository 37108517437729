/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import {currentHost, currentURL} from './utils/uri'

const host = currentHost()

function getConfig(key) {
  return process.env.NODE_ENV === 'production' ? window.config[key] : process.env['VUE_APP_' + key]
}

export default {
  env: process.env.ENV,
  host,
  urls: {
    self: currentURL(),
    api: getConfig('API_ENTRYPOINT')
  }
}
