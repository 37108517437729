<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-app-bar v-if="loading" app flat :clipped-left="false" color="#3d578e">
    <v-btn text :to="{name: 'home'}" color="transparent" :class="$vuetify.breakpoint.mdAndDown ? 'pa-0' : ''">
      <v-img :src="require('@/assets/img/maPread_logo_light.svg')" contain aspect-ratio="1" max-width="180px" width="180px" />
    </v-btn>
    <v-spacer />
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" class="mr-2" v-on="on" @click="$vuetify.theme.dark = !$vuetify.theme.dark">
          <font-awesome-icon :icon="[ 'fas', $vuetify.theme.dark ? 'moon' : 'sun']" size="lg" class="white--text" />
        </v-btn>
      </template>
      <span>Basculer en mode {{ $vuetify.theme.dark ? 'clair' : 'sombre' }}</span>
    </v-tooltip>
    <v-menu bottom offset-y origin="center center" transition="scale-transition" max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small outlined rounded color="white" v-bind="attrs" v-on="on">
          {{ $t('backoffice.myAccount') }}
          <font-awesome-icon :icon="[ 'fal', 'chevron-down']" size="sm" class="ml-2" />
        </v-btn>
      </template>
      <v-list class="pa-0">
        <v-list-item :class="$vuetify.theme.dark ? '' : 'grey lighten-4'">
          <v-list-item-avatar>
            <v-img src="@/assets/img/user-generic.png" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-alt">{{ $auth.user.identity.firstName }} {{ $auth.user.identity.lastName }}</v-list-item-title>
            <v-list-item-subtitle class="o-90 font-alt">{{ $auth.user.username }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item target="_blank" :href="legalNotice">
          <v-list-item-avatar class="mr-1">
            <font-awesome-icon :icon="[ 'fal', 'book']" size="1x" class="o-60" />
          </v-list-item-avatar>
          <v-list-item-title class="o-90 font-alt">Mentions légales</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!isProfessionnalDashboard && userGuideLink" target="_blank" :href="userGuideLink">
          <v-list-item-avatar class="mr-1">
            <font-awesome-icon :icon="[ 'fal', 'book']" size="1x" class="o-60" />
          </v-list-item-avatar>
          <v-list-item-title class="o-90 font-alt">Manuel d'utilisation</v-list-item-title>
        </v-list-item>
        <v-list-item target="_blank" :href="cguLink">
          <v-list-item-avatar class="mr-1">
            <font-awesome-icon :icon="[ 'fal', 'file-contract']" size="1x" class="o-60" />
          </v-list-item-avatar>
          <v-list-item-title class="o-90 font-alt">CGU</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$auth.logout">
          <v-list-item-avatar class="mr-1">
            <font-awesome-icon :icon="[ 'fal', 'power-off']" size="1x" class="o-60" />
          </v-list-item-avatar>
          <v-list-item-title class="o-90 font-alt">{{ $t('backoffice.logout') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'HeaderBar',
    data() {
      return {
        loading: true
      }
    },
    computed: {
      isProfessionnalDashboard() {
        return this.$route.name === 'dashboard-professionnal'
      },
      userGuideLink() {
        let file
        if(this.$auth.user.roles[1] === 'ROLE_PATIENT') {
          file = '/manuals/guide_patient_mapread.pdf'
        } else {
          try {
            const parentPath = this.$route.matched[0].path
            const permissions = this.$auth.user.identity.professionalIdentityEstablishments[0].permissions
            if(permissions.includes('PERMISSION_ADMISSION') && parentPath === '/admissions') {
              file = '/manuals/guide_admissions_mapread.pdf'
            } else if(permissions.includes('PERMISSION_SURVEY') && parentPath === '/surveys') {
              file = '/manuals/guide_post-op_mapread.pdf'
            }
          } catch(e) {
            file = null
          }
        }
        return file
      },
      cguLink() {
        return '/cgu/cgu_fr_ma_pread.pdf'
      },
      legalNotice() {
        return '/cgu/ml_fr_ma_pread.pdf'
      }
    }
  }
</script>

