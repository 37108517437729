/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import { empty } from '@/utils/variables'

export const rules = {
  required: value => {
    return !empty(value) || 'Champ requis'
  },
  length: length => {
    return value => (value || '').length === length || `La valeur doit contenir ${length} caractères`
  },
  minLength: (min) => {
    return value => (value || '').length >= parseInt(min) || `La valeur doit contenir un minimum de ${min} caractères`
  },
  maxLength: (max) => {
    return value => (value || '').length <= parseInt(max) || `La valeur doit contenir au maximum ${max} caractères`
  },
  between: (min, max) => {
    return value =>  empty(value) || (parseFloat(value) >= parseFloat(min) && parseFloat(value) <= parseFloat(max)) || `La valeur doit être comprise entre ${min} et ${max}`
  },
  positive: value =>{
    return empty(value) || parseFloat(value) > 0  || 'Valeur supérieure à zéro requise'
  },
  positiveOrZero: value =>{
    return empty(value) || parseFloat(value) >= 0  || 'Valeur positive requise'
  },
  email: (value) => {
    if (empty(value)) return true
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    return pattern.test(value) || 'Adresse e-mail invalide'
  },
  digit: (value) => {
    const pattern = /^\d*\.?\d*$/
    return empty(value) || pattern.test(value) || 'Valeur numérique requise'
  },
  pattern: (regex, errorMsg) => {
    return value => regex.test(value) || errorMsg
  },
  phone: (value) => {
    if (empty(value)) return true
    const pattern = /^\(?\d{3}\)?[-\s]?\d{3}[-\s]?\d{4}$/
    return pattern.test(value) || 'Numéro de téléphone invalide'
  }
}
