/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import api from '@/modules/professionals/api'

export default {
  namespaced: true,
  state: {
    stays: [],
    totalItems: 0,
    pageCount: 0
  },
  mutations: {
    SET_STAYS(state, stays) {
      state.stays = stays
    },
    SET_TOTAL_ITEMS(state, totalItems) {
      state.totalItems = totalItems
    }
  },
  actions: {
    async loadInProgressStays({commit}, params){
      Object.entries(params).forEach(([key, value]) => {
        if (!value) delete params[key]
      })
      let response = await api.getStays(params)
      commit('SET_STAYS', response.data['hydra:member'])
      commit('SET_TOTAL_ITEMS', response.data['hydra:totalItems'])
    }
  }
}
