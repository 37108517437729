/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import {patientRoutes} from '@/modules/patients/routes'
import {professionalRoutes} from '@/modules/professionals/routes'
import {backofficeRoutes} from '@/modules/backoffice/routes'
import {getInstance as getAuthInstance} from '@/plugins/auth'
import {empty} from '@/utils/variables'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      requiredRoles: ['role_patient', 'role_professional', 'role_admin']
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../modules/auth/Login'),
    props: route => ({hint: route.query.hint}),
    meta: {
      guestOnly: true,
      login: true
    }
  },
  {
    path: '/accept-conditions',
    name: 'accept-conditions',
    component: () => import('../modules/auth/AcceptConditions'),
    props: route => ({hint: route.query.hint}),
    meta: {
      requiredRoles: ['role_user'],
      login: true
    }
  },
  {
    path: '/init-password',
    name: 'init-password',
    component: () => import('../modules/auth/InitPassword'),
    meta: {
      guestOnly: true,
      login: true
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../modules/auth/ResetPassword'),
    meta: {
      guestOnly: true,
      login: true
    }
  },
  {
    path: '/change-email',
    name: 'change-email',
    component: () => import('../modules/auth/ChangeEmail'),
    props: route => ({ userId: route.query.user, email: route.query.email, token: route.query.token }),
    meta: {
      login: true
    }
  },
  ...patientRoutes,
  ...professionalRoutes,
  ...backofficeRoutes,
  {
    path: '*',
    redirect: {name: 'home'}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

// Wait auth before populating router view
router.beforeEach((to, from, next) => {
  const auth = getAuthInstance()

  if (!auth.loading) {
    next()
    return
  }

  auth.$watch('loading', loading => {
    if (!loading) {
      next()
    }
  })
})

router.beforeEach((to, from, next) => {
  const auth = getAuthInstance()
  if (auth.user) {
    if (auth.user.cguValidationDate && to.name === 'accept-conditions') {
      next({name: 'home'})
      return
    } else if(!auth.user.cguValidationDate && to.name !== 'accept-conditions') {
      next({name: 'accept-conditions'})
      return
    }
  }
  next()
})

function getRoleHomepage() {
  const auth = getAuthInstance()
  let target
  if (auth.granted('role_admin')) {
    target = 'dashboard'
  } else if (auth.granted('role_professional')) {
    if (auth.user.identity.professionalIdentityEstablishments[0].permissions.length > 1) {
      target = 'dashboard-professionnal'
    } else if (auth.user.identity.professionalIdentityEstablishments[0].permissions[0] === 'PERMISSION_SURVEY') {
      target = 'surveys-pending'
    } else if (auth.user.identity.professionalIdentityEstablishments[0].permissions[0] === 'PERMISSION_ADMISSION') {
      target = 'admissions-pending'
    }
  } else if (auth.granted('role_patient')) {
    target = 'patient-homepage'
  }

  return target
}

// Verify user access
router.beforeEach(async (to, from, next) => {
  const auth = getAuthInstance()

  if (auth.authenticated) {
    // If the user is authenticated on a page reserved for anonymous
    if (to.matched.some(m => m.meta && m.meta.guestOnly)) {
      if (to.path === '/') {
        throw new Error('Page / must not have the meta guestOnly')
      }
      next('/')
    } else if (to.matched.some(m => m.meta && !empty(m.meta.requiredRoles) && !auth.granted(m.meta.requiredRoles))) {
      let roleHomepage = getRoleHomepage()
      if (roleHomepage === to.name) {
        throw new Error('Bad configuration for route ' + to.path + '. User cannot access to his homepage with roles : ' + auth.roles.join(', '))
      }
      await next({name: roleHomepage})
    }
  } else {
    // If the user is not authenticated on a page that needs to be
    if (to.matched.some(m => !m.meta || !empty(m.meta.requiredRoles))) {
      next({name: 'login'})
    }
  }

  next()
})

// Redirect use to its homepage specific to his roles
router.beforeEach(async (to, from, next) => {
  const auth = getAuthInstance()
  if (to.path !== '/') {
    next()
    return
  }

  let target = getRoleHomepage()

  if (!target) {
    await auth.logout()
  } else if(to.name !== target){
    next({name: target})
  } else {
    next()
  }
})

export default router
