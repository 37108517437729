/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

// Duotone Styles
import {
  faStickyNote as fadStickyNote,
  faSave as fadSave,
  faPhone as fadPhone,
  faQuestion as fadQuestion,
  faExclamationTriangle as fadExclamationTriangle,
  faPollH as fadPollH,
  faCaretDown as fadCaretDown,
  faCaretRight as fadCaretRight,
  faBook as fadBook,
  faInfoCircle as fadInfoCircle,
  faExclamationCircle as fadExclamationCircle,
  faBoxOpen as fadBoxOpen,
  faPlusOctagon as fadPlusOctagon,
  faTimesOctagon as fadTimesOctagon,
  faCheckDouble as fadCheckDouble,
  faTrash as fadTrash,
  faPencil as fadPencil,
  faChartPieAlt as fadChartPieAlt,
  faChevronDoubleLeft as fadChevronDoubleLeft,
  faFileUpload as fadFileUpload,
  faCabinetFiling as fadCabinetFiling,
  faHandHoldingMedical as fadHandHoldingMedical,
  faBookUser as fadBookUser,
  faClinicMedical as fadClinicMedical,
  faTimesCircle as fadTimesCircle,
  faEyeSlash as fadEyeSlash,
  faClipboardListCheck as fadClipboardListCheck,
  faArchive as fadArchive,
  faFolderOpen as fadFolderOpen,
  faFolderPlus as fadFolderPlus,
  faCheckCircle as fadCheckCircle,
  faFolders as fadFolders,
  faFileAlt as fadFileAlt,
  faPen as fadPen,
  faMapMarkerAlt as fadMapMarkerAlt,
  faGlobeEurope as fadGlobeEurope,
  faCalendarDay as fadCalendarDay,
  faVenus as fadVenus,
  faEye as fadEye,
  faFileImage as fadFileImage,
  faFilePdf as fadFilePdf,
  faUser as fadUser,
  faFileMedical as fadFileMedical,
  faEnvelope as fadEnvelope,
  faPauseCircle as fadPauseCircle,
  faPrint as fadPrint,
  faAlarmClock as fadAlarmClock,
  faUserPlus as fadUserPlus,
  faBallot as fadBallot,
  faPlus as fadPlus,
  faUserMd as fadUserMd,
  faPhoneAlt as fadPhoneAlt,
  faAt as fadAt,
  faHospitalUser as fadHospitalUser,
  faHomeAlt as fadHomeAlt,
  faLayerGroup as fadLayerGroup,
  faUsers as fadUsers,
  faGlobe as fadGlobe,
  faAddressBook as fadAddressBook,
  faLockAlt as fadLockAlt,
  faUpload as fadUpload,
  faSpinnerThird as fadSpinnerThird,
  faExclamation as fadExclamation,
  faSearch as fadSearch,
  faAngleDoubleRight as fadAngleDoubleRight,
  faInfo as fadInfo,
  faCalendarCheck as fadCalendarCheck,
  faClock as fadClock,
  faBusinessTime as fadBusinessTime,
  faBellPlus as fadBellPlus,
  faAlarmPlus as fadAlarmPlus,
  faWindowClose as fafWindowClose
} from '@fortawesome/pro-duotone-svg-icons'

// Light Styles
import {
  faSave as falSave,
  faChevronDown as falChevronDown,
  faPowerOff as falPowerOff,
  faLaptopMedical as falLaptopMedical,
  faFileMedical as falFileMedical,
  faSignOutAlt as falSignOutAlt,
  faPeopleArrows as falPeopleArrows,
  faArrowLeft as falArrowLeft,
  faBooksMedical as falBooksMedical,
  faStethoscope as falStethoscope,
  faPencil as falPencil,
  faEllipsisH as falEllipsisH,
  faCommentPlus as falCommentPlus,
  faUserPlus as falUserPlus,
  faLock as falLock,
  faInfoCircle as falInfoCircle,
  faMinus as falMinus,
  faPlus as falPlus,
  faHistory as falHistory,
  faMapMarkerAlt as falMapMarkerAlt,
  faUser as falUser,
  faFileSignature as falFileSignature,
  faUserMdChat as falUserMdChat,
  faUserEdit as falUserEdit,
  faUserTimes as falUserTimes,
  faAt as falAt,
  faPhone as falPhone,
  faBallot as falBallot,
  faAngleDown as falAngleDown,
  faAddressBook as falBAddressBook,
  faFilesMedical as falFilesMedical,
  faUsersMedical as falUsersMedical,
  faTools,
  faTimes as falTimes,
  faTimesCircle as falTimesCircle,
  faBars as falBars,
  faExchange,
  faSignOut,
  faGlobe as falGlobe,
  faAddressBook as falAddressBook,
  faLockAlt as falLockAlt,
  faChevronLeft as falChevronLeft,
  faChevronRight as falChevronRight,
  faCheck as falCheck,
  faCheckSquare,
  faSquare,
  faCircle,
  faDotCircle,
  faIdCard as falIdCard,
  faKey as falKey,
  faUsers as falUsers,
  faNewspaper as falNewsPaper,
  faPencilPaintbrush,
  faClipboardListCheck,
  faCommentAltLines,
  faFilePlus,
  faExclamationCircle,
  faEdit as falEdit,
  faSearch as falSearch,
  faCommentDots as falCommentDots,
  faBell as falBell,
  faExpandWide as falExpandWide,
  faHdd,
  faDownload as falDownload,
  faCalendarCheck as falCalendarCheck,
  faTags as falTags,
  faShareAll as falShareAll,
  faShare as falShare,
  faFileUser as falFileUser,
  faFileCertificate as falFileCertificate,
  faArrowUp as falArrowUp,
  faArrowDown as falArrowDown,
  faBalanceScale as falBalanceScale,
  faBook as falBook,
  faFileContract as falFileContract
} from '@fortawesome/pro-light-svg-icons'

// Regular Styles
import {
  faBars as farBars,
  faGlobe as farGlobe,
  faAddressBook as farAddressBook,
  faLockAlt as farLockAlt,
  faChevronLeft as farChevronLeft,
  faPaperclip as farPaperClip,
  faEllipsisV as farEllipsisV,
  faFilter as farFilter,
  faWindowMinimize as farWindowMinimize,
  faWindowMaximize as farWindowMaximize,
  faCheckCircle as farCheckCircle,
  faInfoCircle as farInfoCircle,
  faExclamationCircle as farExclamationCircle,
  faTimesCircle as farTimesCircle,
  faAt as farAt,
  faSortAmountUp, faSortAmountDown, faSmilePlus, faThumbtack
} from '@fortawesome/pro-regular-svg-icons'

// Solid Styles
import {
  faMoon as fasMoon, faSun as fasSun,faPlus as fasPlus,
  faAngleDown, faCaretDown, faTimesCircle as fasTimesCircle, faCheck as fasCheck, faCheckCircle as fasCheckCircle,
  faCircle as fasCircle, faPaperPlane as fasPaperPlane, faTimes as fasTimes, faTh as fasTh, faSortUp as fasSortUp,
  faFlagCheckered as fasFlagCheckered, faGripVertical as fasGripVertical
} from '@fortawesome/pro-solid-svg-icons'


Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)

library.add(
  falBalanceScale,falBook,falFileContract, fadExclamationTriangle, fadPollH,fadPhone,fadCaretDown,fadCaretRight,fadSave,falSave,fadStickyNote,
  falPencil, falCommentPlus, falUserPlus, falLock, falMinus,falPlus,fadPlus,falHistory,fadUserMd, fadAt, fadPhoneAlt,fadHospitalUser, fadHomeAlt, fadLayerGroup, fadBookUser,
  fadUsers, falCheck, falBallot, falBAddressBook,falInfoCircle,falEllipsisH,falArrowLeft,fadBallot,fadUserPlus, falSignOutAlt, fadPrint,fadFilePdf,fadFileImage,fadHandHoldingMedical,
  faAngleDown, falAngleDown, falTimes, falTimesCircle, falBars, farBars, faExchange, faSignOut, faCommentAltLines,fasMoon,fasSun,fadUser,fadGlobeEurope,fadEyeSlash,fadBoxOpen,fadBook,
  falGlobe, falAddressBook, falLockAlt, fadGlobe, fadAddressBook, fadLockAlt, farGlobe, farAddressBook, farLockAlt,fadAlarmClock,fadFileMedical,fadCheckCircle,fadExclamationCircle,
  faCaretDown, falChevronLeft, falChevronRight, farChevronLeft, faSquare, faCheckSquare, falIdCard, falKey, falUsers,fadEye,fadCalendarDay,fadFolders,fadClinicMedical,
  falNewsPaper, faPencilPaintbrush, faClipboardListCheck, faCircle, faDotCircle, farPaperClip, fasTimesCircle, fadUpload,fadPen,fadFileAlt,falLaptopMedical,fadInfoCircle,
  faFilePlus, faExclamationCircle, falAt, falPhone, farEllipsisV, falUserTimes, falUserEdit, falUserMdChat, falFileSignature,falArrowUp,falArrowDown,fadFileUpload,falChevronDown,
  falUser, falMapMarkerAlt, fasCheckCircle, fasCheck, fasCircle, falEdit, farFilter, falSearch, falFilesMedical,falFileMedical,fasSortUp,falPowerOff,fasPlus,
  falUsersMedical, faTools, falCommentDots, falBell, falExpandWide, fasPaperPlane, fasTimes, fadSpinnerThird,fadPauseCircle,fadMapMarkerAlt,fadTimesCircle,fadChartPieAlt,
  farWindowMinimize, farWindowMaximize, faSortAmountUp, faSortAmountDown, faHdd, falCalendarCheck, falTags, falShareAll,fadVenus,fadClipboardListCheck,
  falShare, falFileUser, falFileCertificate,falStethoscope, falBooksMedical, faSmilePlus, faThumbtack, fadExclamation, falPeopleArrows,fadFolderPlus,fadChevronDoubleLeft,
  farCheckCircle, farTimesCircle, farInfoCircle, farExclamationCircle, fasTh, farAt, falDownload, fadEnvelope, fadSearch, fadArchive,fadFolderOpen,fadCabinetFiling,
  fadTrash, fadPencil, fadCheckDouble,fadTimesOctagon,fadPlusOctagon, fadAngleDoubleRight, fadQuestion, fadInfo, fasFlagCheckered,fadBellPlus,fadAlarmPlus,
  fasGripVertical, fadCalendarCheck, fadClock, fadBusinessTime, fafWindowClose
)

const APTELECARE_ICONSET = {
  expand: { component: FontAwesomeIcon, props: { icon: ['fal', 'angle-down']} }, // Nav submenu
  subgroup: { component: FontAwesomeIcon, props: { icon: ['fas', 'angle-down']} },
  dropdown: { component: FontAwesomeIcon, props: { icon: ['fal', 'angle-down']} },
  clear: { component: FontAwesomeIcon, props: { icon: ['fal', 'times']} },
  close: { component: FontAwesomeIcon, props: { icon: ['fal', 'times']} },
  delete: { component: FontAwesomeIcon, props: { icon: ['fal', 'times-circle']} },
  edit: { component: FontAwesomeIcon, props: { icon: ['fal', 'edit']} },
  menu: { component: FontAwesomeIcon, props: { icon: ['far', 'bars']} },
  back: { component: FontAwesomeIcon, props: { icon: ['far', 'chevron-left']} },
  prev: { component: FontAwesomeIcon, props: { icon: ['fal', 'chevron-left']} },
  next: { component: FontAwesomeIcon, props: { icon: ['fal', 'chevron-right']} },
  checkboxOn: { component: FontAwesomeIcon, props: { icon: ['fal', 'check-square']} },
  checkboxOff: { component: FontAwesomeIcon, props: { icon: ['fal', 'square']} },
  radioOn: { component: FontAwesomeIcon, props: { icon: ['fal', 'dot-circle']} },
  radioOff: { component: FontAwesomeIcon, props: { icon: ['fal', 'circle']} },
  comment: { component: FontAwesomeIcon, props: { icon: ['fal', 'comment-alt-lines']} },
  dots: { component: FontAwesomeIcon, props: { icon: ['far', 'ellipsis-v']} },
  filter: { component: FontAwesomeIcon, props: { icon: ['far', 'filter']} },
  pin: { component: FontAwesomeIcon, props: { icon: ['far', 'thumbtack']}},
  success: { component: FontAwesomeIcon, props: { icon: ['far', 'check-circle']}},
  info: { component: FontAwesomeIcon, props: { icon: ['far', 'info-circle']}},
  warning: { component: FontAwesomeIcon, props: { icon: ['far', 'exclamation-circle']}},
  error: { component: FontAwesomeIcon, props: { icon: ['far', 'times-circle']}},
  eye: { component: FontAwesomeIcon, props: { icon: ['fad', 'eye']}},
  eyeSlash: { component: FontAwesomeIcon, props: { icon: ['fad', 'eye-slash']}}
}


export { APTELECARE_ICONSET }
