/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

export const patientRoutes = [
  {
    path: '/patient/admission', // rename this, define proper URI
    component: () => import('./views/PatientSection'),
    meta: {
      requiredRoles: ['role_patient']
    },
    children: [
      {
        path: '',
        name: 'patient-homepage',
        component: () => import('./components/PatientHomePage')
      },
      {
        path: 'form/:stayId/:comeFromList?',
        name: 'admission-form',
        component: () => import('./components/AdmissionForm')
      },
      {
        path: 'survey/:id',
        name: 'survey-form',
        component: () => import('../surveys/views/professionals/SurveyCard'),
        props: true
      }
    ]
  }
]
